<template>
    <v-sheet>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-spacer></v-spacer> 
                <v-btn v-for="(list, i) in filledBtn" :key="i" @click="onClickBtn(i)"
                    :disabled="list.disabled"
                    :class="list.class" samll height="30" :color="list.color" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                프로그램
            </v-col>
            <v-col class="d-flex justify-start align-center pl-1 py-1" cols="10">
                <span class="inline-block" style="width:70%">
                    <v-text-field
                        v-model="prgInf.prgNm"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    color="blue200" height="26"    
                    @click="dialog.splice(1, 1, true)"                
                    icon>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                <v-icon size="20">mdi-text-box-outline</v-icon>
                            </div>
                        </template>
                        <span>프로그램 선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>            
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                프로그램 유형
            </v-col>
            <v-col class="tableBR d-flex justify-start align-center pl-1 py-1" cols="4">
                <!-- 프로그램 유형 select -->
                 <span class="inline-block" style="width:80%">
                     <v-select 
                         v-model="prgInf.prgTypMngPk"
                         :items="$parent.$parent.prgTyplist"
                         item-text="prgTypNm"
                         item-value="prgTypMngPk"
                         dense hide-details outlined></v-select>
                 </span>
                <!-- 프로그램 유형추가 btn -->
                <v-btn                    
                    style="margin-top: 2px;"
                    @click="onModalType"
                    class="ml-1 mr-1" color="blue200" height="26" icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                지표
            </v-col>
            <v-col class="fontOneRem pa-1" cols="4">
                <v-text-field
                    v-model="prgInf.idxCdNm" :disabled="true"
                    height="28" hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                장소
            </v-col>
            <v-col class="tableBR d-flex justify-start align-center pa-1" cols="4">
                <v-text-field
                    v-model="prgInf.plc"
                    height="28" hide-details outlined dense>
                </v-text-field>
            </v-col>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                진행자
            </v-col>
            <v-col class="fontOneRem d-flex justify-start align-center pa-1" cols="4">
                <span class="inline-block" style="width:100px">
                    <v-text-field
                        v-model="prgInf.mc" :disabled="true"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="brown lighten-3 d-inline-block ml-1" style="padding:0px 1px 0px 2px !important" 
                    @click="onEmpSlct"
                    min-width="30" height="28" dark small depressed>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                준비물
            </v-col>
            <v-col class=" d-flex justify-start align-center pa-1" cols="10">
                <v-text-field
                    v-model="prgInf.mtrl" 
                    height="28" hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                목표
            </v-col>
            <v-col class=" d-flex justify-start align-center pa-1" cols="10">
                <v-text-field
                    v-model="prgInf.goal" 
                    height="28" hide-details outlined dense>
                </v-text-field>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR py-1" cols="2">
                <div>
                    <div>프로그램 내용</div>
                    <div>&#40;진행과정&#41;</div>
                </div>
            </v-col>
            <v-col class=" d-flex justify-start align-center pa-1" cols="10">
                <v-textarea dense outlined hide-details rows="14"
                    v-model="prgInf.prgCntnt"
                    class="rmrksArea" no-resize  
                    
                ></v-textarea>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                기대효과
            </v-col>
            <v-col class=" d-flex justify-start align-center pl-1 py-1" cols="10">
                <v-textarea dense outlined hide-details rows="14"
                    v-model="prgInf.expEfc"
                    class="rmrksArea" no-resize  
                    
                ></v-textarea>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR py-1" cols="2">
                <div>
                    <div>기능회복훈련</div>
                    <div>&#40;급여제공기록지포함&#41;</div>
                </div>
            </v-col>
            <v-col class="pl-1 py-1" cols="10">
                <span v-for="(item, idx)  in $parent.$parent.vCd38" :key="idx" class="d-inline-block" style="width:140px;">
                    <v-checkbox 
                        style="margin-top: 3px !important;"
                        v-model="prgInf.fncRcvCd" 
                        :label="item.valcdnm" :value="item.valcd" 
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>    
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="600">
                <PrgrmCategory ref="PrgrmCategory" @isClose="dialog.splice(0, 1, false)" @reload="$parent.$parent.getTyplist()"></PrgrmCategory>
            </v-dialog>    
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <PrgrmCmmSelect  
                    v-if="dialog[1]"
                    @isClose="dialog.splice(1, 1, false)" 
                    @setPrgInfo="getMdlPrgInf"
                ></PrgrmCmmSelect>
            </v-dialog>    
        </div>
    </v-sheet>
</template>

<script>
import PrgrmCategory from '../../components/prgrm/PrgrmCategory.vue';
import PrgrmCmmSelect from '../../components/prgrm/PrgrmCmmSelect.vue';

import { http } from '@/api/baseAxios';

export default {
    name: 'PrgInfrmMgmt',

    components: {
        PrgrmCategory,
        PrgrmCmmSelect,
    },
    computed: {
        getPrgInfPk() {
            return this.$parent.$parent.sltPk;
        },
        getSubRunEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },
    watch: {
        'prgInf.prgTypMngPk' : function (value) {
            if(value > 0){                
                let typIdx = this.$parent.$parent.prgTyplist.findIndex(v=> v.prgTypMngPk == value)
                if(typIdx > -1){
                    this.prgInf.idxCd =  this.$parent.$parent.prgTyplist[typIdx].idxCd
        
                    this.$parent.$parent.vCd40.forEach(cd => {
                        if(cd.valcd == this.prgInf.idxCd) this.prgInf.idxCdNm = cd.valcdnm
                    });
                }
            } else {
                this.prgInf.idxCdNm = ''
            }
        },
        'getPrgInfPk' : function(value){            
            this.getPrgInfInfo(value)
        },
        getSubRunEvt: function (bol) {
            if(bol && this.mcAct){
                let obj = this.$store.getters.getSubOutObj
                this.prgInf.mc = obj.name
                this.prgInf.mcMmbrPk = obj.mmbrPk
                
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.mcAct = false
            }

            if(bol && this.rmPrgAct){
                this.rmInsPrgInf(this.prgInf)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmPrgAct = false
            }
        },
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },
    created: function () {
        
    },
    methods: {
         //메세지 출력
         onAplct: function (msg) {
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
            obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            obj.title = msg      // 경고 타이틀

            this.$store.commit('setWrnnInf', obj)
        },

        //프로그램정보 단건
        getPrgInfInfo:function(infPk){  
            this.filledBtn[2].disabled = true

            this.prgInf.prgInfPk = infPk
            this.clearPrgInf()

            if(this.prgInf.prgInfPk > 0){
                this.filledBtn[2].disabled = false

                let obj = {
                    prgInfPk:this.prgInf.prgInfPk
                }
    
                http.post('prgrm/selPrgInfInfo', obj)
                    .then((response) => ( this.rstPrgInfInfo(response.data) ))
                    .catch((error) => console.log('connect error /prgrm/selPrgInfInfo : ' + error))
            } 
        },

        //프로그램 정보 저장
        setInsPrgInf:function(obj){
            http.post('prgrm/insPrgInf', obj)
                    .then((response) => ( this.rstPrgInfIns(response.data) ))
                    .catch((error) => console.log('connect error /prgrm/insPrgInf : ' + error))
        },

        //프로그램 정보 삭제
        rmInsPrgInf:function(obj){
            http.post('prgrm/delPrgInf', obj)
                    .then((response) => ( this.rstInsPrgInfRm(response.data) ))
                    .catch((error) => console.log('connect error /prgrm/insPrgInf : ' + error))
        },

        //프로그램 선택 후 조회
        getMdlPrgInf:function(itm){
            http.post('prgrm/selPrgInfShtInfo', itm)
                .then((response) => ( this.rstMdlPrgInf(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램 정보 셋팅
        rstPrgInfInfo:function(res){

            if(res != null &&  res != ''){   
                this.setPrgInf(res)
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '프로그램 정보 조회 실패하였습니다.'         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //프로그램 저장 결과 반영
        rstPrgInfIns:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if (res.statusCode == 200) {
                obj.tmr = 3000
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)    
                this.$parent.$parent.reset(this.prgInf.prgInfPk)     
            } else {
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }            
        },

        //프로그램 삭제 결과 반영
        rstInsPrgInfRm:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if (res.statusCode == 200) {
                obj.tmr = 3000
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)         
                this.$parent.$parent.reset()     
            } else {
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }            
        },

        //결과 :: 프로그램 선택 결과 조회
        rstMdlPrgInf:function(res){

            if(res.statusCode == '200'){                

                this.prgInf.prgNm               = res.result.prgInf.prgNm
              
                this.prgInf.fncRcvCd.splice(0)

                if(res.result.prgInf.fncRcvCd != null && res.result.prgInf.fncRcvCd != undefined){
                    res.result.prgInf.fncRcvCd.forEach(itm => {
                        this.prgInf.fncRcvCd.push(itm)
                    });
                }

                this.prgInf.expEfc              = res.result.prgInf.expEfc
                this.prgInf.goal                = res.result.prgInf.goal                
                this.prgInf.mtrl                = res.result.prgInf.mtrl                
                this.prgInf.prgCntnt            = res.result.prgInf.prgCntnt

                this.prgInf.prgbnfcrgrinfpks.splice(0)

                this.prgInf.shtMda.splice(0)

                if(res.result.prgInf.shtMda != null && res.result.prgInf.shtMda != undefined){
                    res.result.prgInf.shtMda.forEach(itm => {
                        this.prgInf.shtMda.push(itm)
                    });
                }

                this.prgInf.shtSbjt.splice(0)

                if(res.result.prgInf.shtSbjt != null && res.result.prgInf.shtSbjt != undefined){
                    res.result.prgInf.shtSbjt.forEach(itm => {
                        this.prgInf.shtMda.push(itm)
                    });
                }
                this.prgInf.shtTyp.splice(0)

                if(res.result.prgInf.shtTyp != null && res.result.prgInf.shtTyp != undefined){
                    res.result.prgInf.shtTyp.forEach(itm => {
                        this.prgInf.shtTyp.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //프로그램정보 초기화
        clearPrgInf:function(){
            let obj = {
                prgNm               : '',
                prgTypMngPk         : 0,
                idxCd               : '',
                idxCdNm             : '',
                bnfcrgrNms          : null,
                fncRcvCd            : null,
                expEfc              : '',
                goal                : '',
                mc                  : '',
                mcMmbrPk            : 0,
                mtrl                : '',
                plc                 : '',
                prgCntnt            : '',
                prgbnfcrgrinfpks    : null,
                shtMda              : null,
                shtSbjt             : null,
                shtTyp              : null,
            }

            this.setPrgInf(obj)
        },

        setPrgInf:function(obj){            
            this.prgInf.prgNm               = obj.prgNm
            this.prgInf.prgTypMngPk         = obj.prgTypMngPk
            this.prgInf.idxCd               = obj.idxCd
            this.prgInf.idxCdNm             = obj.idxCdNm
            this.prgInf.bnfcrgrNms.splice(0)
            
            if(obj.bnfcrgrNms != null && obj.bnfcrgrNms != undefined){
                obj.bnfcrgrNms.forEach(itm => {
                    this.prgInf.bnfcrgrNms.push(itm)
                });
            }

            this.prgInf.fncRcvCd.splice(0)

            if(obj.fncRcvCd != null && obj.fncRcvCd != undefined){
                obj.fncRcvCd.forEach(itm => {
                    this.prgInf.fncRcvCd.push(itm)
                });
            }

            this.prgInf.expEfc              = obj.expEfc
            this.prgInf.goal                = obj.goal
            this.prgInf.mc                  = obj.mc
            this.prgInf.mcMmbrPk            = obj.mcMmbrPk
            this.prgInf.mtrl                = obj.mtrl
            this.prgInf.plc                 = obj.plc
            this.prgInf.prgCntnt            = obj.prgCntnt

            this.prgInf.prgbnfcrgrinfpks.splice(0)

            if(obj.prgbnfcrgrinfpks != null && obj.prgbnfcrgrinfpks != undefined){
                obj.prgbnfcrgrinfpks.forEach(itm => {
                    this.prgInf.prgbnfcrgrinfpks.push(itm)
                });
            }

            this.prgInf.shtMda.splice(0)

            if(obj.shtMda != null && obj.shtMda != undefined){
                obj.shtMda.forEach(itm => {
                    this.prgInf.shtMda.push(itm)
                });
            }

            this.prgInf.shtSbjt.splice(0)

            if(obj.shtSbjt != null && obj.shtSbjt != undefined){
                obj.shtSbjt.forEach(itm => {
                    this.prgInf.shtMda.push(itm)
                });
            }
            this.prgInf.shtTyp.splice(0)

            if(obj.shtTyp != null && obj.shtTyp != undefined){
                obj.shtTyp.forEach(itm => {
                    this.prgInf.shtTyp.push(itm)
                });
            }
        },

        //진행자 선택
        onEmpSlct: function () {
            let obj = {}
            obj.empTab = true
            obj.extEmpTab = true
            obj.wrtDt = this.$moment().format('YYYYMMDD')

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 700, prsst: true, typ: '3001', inObj: obj, outObj: null, runEvt: false,
            });

            this.mcAct = true
        },

        //사전 프로그램 저장 확인
        preInsPrgInf:function(){
            
            if(this.prgInf.prgNm == '') return this.onAplct('프로그램명을 입력해주세요.')
            if(this.prgInf.prgTypMngPk == 0 || this.prgInf.prgTypMngPk == '') return this.onAplct('프로그램 유형을 선택해주세요.')
            if(this.prgInf.plc == '') return this.onAplct('장소을 입력해주세요.')
            if(this.prgInf.mc == '') return this.onAplct('진행자을 입력해주세요.')
            if(this.prgInf.mtrl == '') return this.onAplct('준비물을 입력해주세요.')
            if(this.prgInf.goal == '') return this.onAplct('목표을 입력해주세요.')
            if(this.prgInf.prgCntnt == '') return this.onAplct('프로그램 내용을 입력해주세요.')
            if(this.prgInf.expEfc == '') return this.onAplct('기대효과을 입력해주세요.')
            if(this.prgInf.fncRcvCd.length == 0) return this.onAplct('기능회복훈련을 선택해주세요.')

            let obj = {
                prgInfPk            : this.prgInf.prgInfPk,
                prgNm               : this.prgInf.prgNm,
                prgTypMngPk         : this.prgInf.prgTypMngPk,
                idxCd               : this.prgInf.idxCd,
                idxCdNm             : this.prgInf.idxCdNm,
                bnfcrgrNms          : [],
                fncRcvCd            : [],
                expEfc              : this.prgInf.expEfc,
                goal                : this.prgInf.goal,
                mc                  : this.prgInf.mc,
                mcMmbrPk            : this.prgInf.mcMmbrPk,
                mtrl                : this.prgInf.mtrl,
                plc                 : this.prgInf.plc,
                prgCntnt            : this.prgInf.prgCntnt,
                prgbnfcrgrinfpks    : [],
                shtMda              : [],
                shtSbjt             : [],
                shtTyp              : [],
            }

            
            this.prgInf.bnfcrgrNms.forEach(itm => {
                obj.bnfcrgrNms.push(itm)
            });

            this.prgInf.fncRcvCd.forEach(itm => {
                obj.fncRcvCd.push(itm)
            });

            this.prgInf.prgbnfcrgrinfpks.forEach(itm => {
                obj.prgbnfcrgrinfpks.push(itm)
            });

            this.prgInf.shtMda.forEach(itm => {
                obj.shtMda.push(itm)
            });
            
            this.prgInf.shtSbjt.forEach(itm => {
                obj.shtMda.push(itm)
            });

            this.prgInf.shtTyp.forEach(itm => {
                obj.shtTyp.push(itm)
            });
            
            this.setInsPrgInf(obj)
        },

        //사전 프로그램 삭제 
        preRmPrgInf:function(){               

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '프로그램'
            obj.cntnt = [this.prgInf.prgNm+'을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmPrgAct = true
        },

        onClickBtn:function(key){
            switch (key) {
                case 0 :
                    this.$parent.$parent.trColorChange()
                    break;
                case 1 :
                    this.preInsPrgInf()
                    break;
                case 2 :
                    this.preRmPrgInf()
                    break;
                default:
                    break;
            }
        },

        onModalType() {
            this.$refs.PrgrmCategory.onShow()
            this.dialog.splice(0, 1, true)
        },  

        
        
    },

    data: () => ({
        savBtn  : true,
        dialog  : [false,],
        mcAct   : false,
        rmPrgAct : false,
        prgInf  : {
            prgInfPk            : 0,
            prgNm               : '',
            prgTypMngPk         : 0,
            idxCd               : '',
            idxCdNm             : '',
            bnfcrgrNms          : [],
            fncRcvCd            : [],
            expEfc              : '',
            goal                : '',
            mc                  : '',
            mcMmbrPk            : 0,
            mtrl                : '',
            plc                 : '',
            prgCntnt            : '',
            prgbnfcrgrinfpks    : [],
            shtMda              : [],
            shtSbjt             : [],
            shtTyp              : [],
        },
        filledBtn: [                                    
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '신규', type: 'action', width: '600px', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action', width: '500px', visible: true, disabled: true, },
        ],
    })
}
</script>